<template>
  <div class="bugconfigDetail">
    <el-row style="margin-bottom: 30px">
      <el-button icon="el-icon-s-management" type="primary" @click="handle('form')">确定</el-button>
      <el-button icon="el-icon-close" @click="quit()">取消</el-button>
    </el-row>
    <Base-section :name="isSave ? '添加' : '编辑'">
      <el-form ref="form" :rules="rules" :model="bugform" label-width="120px">
        <el-row>
          <el-col :offset="0" :span="6">
            <el-form-item label="停机名称" prop="reason">
              <el-input v-model="bugform.reason"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="6">
            <el-form-item label="描述">
              <el-input v-model="bugform.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Base-section>
  </div>
</template>

<script>
import BaseSection from '@/components/section/base-section';
export default {
  props: ['id', 'level'],
  data() {
    return {
      isSave: true,
      bugform: {
        reason: '',
        description: ''
      },
      rules: {
        reason: [{ required: true, message: '请输入停机名称', trigger: 'blur' }]
      }
    };
  },
  components: { BaseSection },
  async mounted() {
    this.isSave = false;
    if (this.$route.path.indexOf('/add') > 1) {
      this.isSave = true;
    } else {
      const { items } = await this.$apis.bugconfig.get({ id: this.id, level: this.level });
      this.bugform.reason = items[0].reason;
      this.bugform.description = items[0].description;
    }
  },
  methods: {
    handle(form) {
      if (!this.bugform.reason.trim()) {
        this.$message.warning('停机名称不能为全空格');
        return false;
      }
      if (this.bugform.reason.trim().length > 16) {
        this.$message.warning('停机名称不能超过16个字符');
        return false;
      }
      if (this.bugform.description.trim().length > 64) {
        this.$message.warning('停机描述不能超过64个字符');
        return false;
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          const { reason, description } = this.bugform;
          let data = {
            reason: reason.trim(),
            description,
            parentId: this.id || null
          };
          let Wfail;
          if (this.isSave) {
            const { fail } = await this.$apis.bugconfig.save(data);
            Wfail = fail;
          } else {
            data = {
              reason,
              description,
              id: this.id || null
            };
            const { fail } = await this.$apis.bugconfig.update(data);
            Wfail = fail;
          }
          if (!Wfail) {
            this.$router.push('/bugconfig');
          }
        } else {
          this.$message.warning('请正确填写停机信息');
          return false;
        }
      });
    },
    quit() {
      this.$router.push('/bugconfig');
    }
  }
};
</script>
